<template>
  <div v-if="env === 'development'">
    <h4 style="text-align: center">温州康宁医院-云医院APP下载页</h4>
    <div style="text-align: center;">
      <span style="font-weight: bold; font-size: 16px;margin-right: 10px;">测试版下载：</span>
        <a href="https://cloudhospitalweb.knjs.net/static/cloudhospital/cloudhospital.apk" style="margin-right: 25px;">安卓下载</a>
        <a href="itms-services://?action=download-manifest&url=https://cloudhospitalweb.knjs.net/static/iPhoneApp.plist">苹果下载</a>
    </div>
    <div style="text-align: center;">
      <span style="font-weight: bold; font-size: 16px;margin-right: 10px;">正式版下载：</span>
        <a href="https://cloudhospitalweb.knjs.net/static/cloudhospital/cloudhospitalpro.apk" style="margin-right: 25px;">安卓下载</a>
        <a href="itms-services://?action=download-manifest&url=https://cloudhospitalweb.knjs.net/static/iPhoneAppPro.plist">苹果下载</a>
    </div>
    <div style="text-align: center;">
      <span style="font-weight: bold; font-size: 16px;margin-right: 10px;">互联网医院医生端：</span>
        <a href="https://cloudhospitalweb.knjs.net/static/cloudhospital/cloudhospitalpro1.apk" style="margin-right: 25px;">安卓下载</a>
        <a href="itms-services://?action=download-manifest&url=https://cloudhospitalweb.knjs.net/static/iPhoneAppPro1.plist">苹果下载</a>
    </div>
    <van-form ref="identityInfoForm" label-width="100px" colon>
      <van-field
          readonly
          clickable
          :value="postData.necessityText"
          label="必要性"
          @click="necessity.showPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="necessity.showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="necessity.columns"
            @confirm="(picker)=>{ confirmPop(picker, 'necessity'); necessity.showPicker = false;}"
            @cancel="necessity.showPicker = false">
        </van-picker>
      </van-popup>

      <van-field
          readonly
          clickable
          :value="postData.typeText"
          label="系统"
          @click="type.showPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="type.showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="type.columns"
            @confirm="(picker)=>{ confirmPop(picker, 'type'); type.showPicker = false;}"
            @cancel="type.showPicker = false">
        </van-picker>
      </van-popup>

      <van-field
          v-model="postData.versionNumber"
          label="最新版本号"
          :rules="[{ required: true }]"
      />
      <van-field
          v-model="postData.user"
          label="上传人"
      />

      <van-field
          readonly
          clickable
          :value="postData.versionText"
          label="库类"
          @click="version.showPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="version.showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="version.columns"
            @confirm="(picker)=>{ confirmPop(picker, 'version'); version.showPicker = false;}"
            @cancel="version.showPicker = false">
        </van-picker>
      </van-popup>

      <van-field
          v-model="postData.content"
          label="更新说明"
      />

      <div style="text-align: center;">
        <van-button type="primary" @click="submitUpload()" style="width: 50%;margin-top: 10px;">确认上传</van-button>
      </div>
    </van-form>

    <div style="text-align: center;">
      <h5 style="margin: 5px 0 5px;">测试库软件版本号</h5>
      <span style="margin-right: 25px;">安卓：{{ versionNumber.testAndroid }}</span>
      <span>苹果：{{ versionNumber.testIPhone }}</span>
    </div>
    <div style="text-align: center;">
      <h5 style="margin: 5px 0 5px;">正式库软件版本号</h5>
      <span style="margin-right: 25px;">安卓：{{ versionNumber.proAndroid }}</span>
      <span>苹果：{{ versionNumber.proIPhone }}</span>
    </div>
    <div style="text-align: center;">
      <h5 style="margin: 5px 0 5px;">互联网医院软件版本号</h5>
      <span style="margin-right: 25px;">安卓：{{ versionNumber.internetAndroid }}</span>
      <span>苹果：{{ versionNumber.internetIPhone }}</span>
    </div>

    <div style="margin-top: 30px;">
      <van-field
          v-show="!showDecryptFun"
          v-model="methodPassword"
          label="开启功能"
          placeholder="请输入密码"
          @change="checkPassword()"
          @keyup.enter="checkPassword()"
      />
      <template v-if="showDecryptFun">
        <van-field
            v-model="secretData"
            label="密文"
            placeholder="请输入密文"
        />
        <div style="text-align: center;">
          <van-button type="primary" @click="decrypt()" style="width: 50%;margin-top: 10px;">解密</van-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import stringUtil from "@/utils/string";
import Axios from 'axios';
import { password } from "@/utils/index.js";

const urlpro="https://cloudhospitalapipro.knjs.net/";   //正式api地址
const urlcs="https://cloudhospitalapi.knjs.net/";      //测试api地址
const url1="https://cloudhospitalweb.knjs.net/static/";

const axios = Axios.create({
  baseURL: '', // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
})

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
      // 在发送请求之前做些什么
      return config
    },
    error => {
      // 对请求错误做些什么
      console.log(error) // for debug
      return Promise.reject(error)
    }
)

// response interceptor
axios.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      // 对响应数据做点什么
      // const res = stringUtil.sm4decryptData(response.data);
      const res = response.data;
      try {
        res.resultData = JSON.parse(stringUtil.sm4decryptData(res.ResultData));
      }catch {
        res.resultData = stringUtil.sm4decryptData(res.ResultData);
      }
      res.resultStatus = res.ResultStatus;
      // if the custom code is not 20000, it is judged as an error.
      if (res.resultStatus != 0) {
        return Promise.reject(res.msg || 'error')
      } else {
        return res.resultData
      }
    },
    error => {
      // 对响应错误做点什么
      console.log('err' + error.message) // for debug
      return Promise.reject(error)
    }
)

export default {
  name: '',
  data(){
    return {
      secretData: '',
      methodPassword: '',
      showDecryptFun: false,

      env: process.env.NODE_ENV,
      postData: {
        necessity: '1',
        necessityText: '必要',
        type: '2',
        typeText: '苹果和安卓',
        version: '0',
        versionText: '测试',
        versionNumber: '',
        user: '',
        project: '1',//总院云医院
        url: '',
        path: '',
      },
      necessity: {
        showPicker: false,
        columns:[{
          text: '必要',
          value: '1'
        },{
          text: '不必要',
          value: '0'
        }],
      },
      type: {
        showPicker: false,
        columns:[
          {
            text: '苹果',
            value: '0'
          },
          {
            text: '安卓',
            value: '1'
          },
          {
            text: '苹果和安卓',
            value: '2'
          }
        ],
      },
      version: {
        showPicker: false,
        columns:[
          {
            text: '测试',
            value: '0'
          },
          {
            text: '正式',
            value: '1'
          },
          {
            text: '互联网',
            value: '2'
          },
        ],
      },
      versionNumber: {
        testAndroid: '',
        testIPhone: '',
        proAndroid: '',
        proIPhone: '',
        internetAndroid: '',
        internetIPhone: '',
      }
    }
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData(){
      axios.get(urlcs + "api/User/GetUpdateInfo?versionNum=&type=0&project=1").then(res => {
        this.versionNumber.testIPhone = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
      axios.get(urlcs + "api/User/GetUpdateInfo?versionNum=&type=1&project=1").then(res => {
        this.versionNumber.testAndroid = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
      axios.get(urlpro+"api/User/GetUpdateInfo?versionNum=&type=0&project=1").then(res => {
        this.versionNumber.proIPhone = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
      axios.get(urlpro+"api/User/GetUpdateInfo?versionNum=&type=1&project=1").then(res => {
        this.versionNumber.proAndroid = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
      axios.get(urlpro+"api/User/GetUpdateInfo?versionNum=&type=0&project=2").then(res => {
        this.versionNumber.internetIPhone = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
      axios.get(urlpro+"api/User/GetUpdateInfo?versionNum=&type=1&project=2").then(res => {
        this.versionNumber.internetAndroid = res.versionNum;
      }).catch(err => {
        console.log(err);
      });
    },
    confirmPop(picker, field){
      this.postData[field] = picker.value;
      this.postData[field + 'Text'] = picker.text;
    },
    submitUpload(){
      //type: android 1, ios 0
      //project: test 0, pro 1
      if(this.postData.version == "0"){
        this.postData.url =urlcs+ "api/User/AddUpdateInfo";
        if(this.postData.type == "0"){
          this.postData.path="itms-services://?action=download-manifest&url="+url1+"iPhoneApp.plist";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.loadData();
          });
        }
        else if(this.postData.type == "1"){
          this.postData.path= url1+"cloudhospital/cloudhospital.apk";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功')
            this.loadData();
          });
        }else{
          this.postData.path = "itms-services://?action=download-manifest&url="+url1+"iPhoneApp.plist";
          this.postData.type = "0";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.postData.path= url1+"cloudhospital/cloudhospital.apk";
            this.postData.type = "1";
            axios.post(this.postData.url, this.postData).then(() => {
              this.$toast('上传成功');
              this.loadData();
            });
          });
        }
      }else if(this.postData.version == "1"){
        this.postData.url = urlpro+ "api/User/AddUpdateInfo";
        if(this.postData.type=="0"){
          this.postData.path="itms-services://?action=download-manifest&url="+url1+"iPhoneApp.plist";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.loadData();
          });
        }
        else if(this.postData.type=="1"){
          this.postData.path= url1+ "cloudhospital/cloudhospitalpro.apk";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.loadData();
          });
        }else{
          this.postData.path="itms-services://?action=download-manifest&url="+url1+"iPhoneApp.plist";
          this.postData.type = "0";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.postData.path= url1+ "cloudhospital/cloudhospitalpro.apk";
            this.postData.type = "1";
            axios.post(this.postData.url, this.postData).then(() => {
              this.$toast('上传成功');
              this.loadData();
            });
          });
        }
      }else{
        this.postData.url = urlpro+ "api/User/AddUpdateInfo";
        if(this.postData.type=="0"){
          this.postData.project = '2';
          this.postData.path="itms-services://?action=download-manifest&url="+url1+"iPhoneAppPro1.plist";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.loadData();
          });
        }
        else if(this.postData.type=="1"){
          this.postData.project = '2';
          this.postData.path= url1+ "cloudhospital/cloudhospitalpro1.apk";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.loadData();
          });
        }else{
          this.postData.project = '2';
          this.postData.path="itms-services://?action=download-manifest&url="+url1+"iPhoneAppPro1.plist";
          this.postData.type = "0";
          axios.post(this.postData.url, this.postData).then(() => {
            this.$toast('上传成功');
            this.postData.project = '2';
            this.postData.path= url1+ "cloudhospital/cloudhospitalpro1.apk";
            this.postData.type = "1";
            axios.post(this.postData.url, this.postData).then(() => {
              this.$toast('上传成功');
              this.loadData();
            });
          });
        }
      }
    },
    checkPassword(){
      if(this.methodPassword === password){
        this.showDecryptFun = true;
      }else{
        this.$toast('密码错误');
      }
    },
    decrypt(){
      try {
        console.log(JSON.parse(stringUtil.sm4decryptData(this.secretData)));
      }catch (e) {
        console.log(stringUtil.sm4decryptData(this.secretData));
      }
    },
  }
}
</script>

<style scoped="" lang="less">
</style>
